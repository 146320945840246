"use client";

import posthog from "posthog-js";
import { PostHogProvider as CoreProvider } from "posthog-js/react";
import { useEffect } from "react";

import { env } from "@tweetdm/env/client";

export const PostHogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (env.NEXT_PUBLIC_STAGE !== "production") return;

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: "/i",
      ui_host: "https://us.posthog.com",
      person_profiles: "identified_only",
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true,
    });
  }, []);

  return <CoreProvider client={posthog}>{children}</CoreProvider>;
};
